<template>
	<v-tooltip top open-delay="500">
		<template v-slot:activator="{on}">
			<v-btn
					v-show="show"
					:fixed="fixed"
					:icon="icon"
					:fab="fab"
					:block="block"
					:color="color ? color : icon ? '':'cell'"
					class="font-weight-bold transition-cubic"
					:class="[icon ? '' : tile ? 'rounded-0': fab ? '':'rounded-lg', classes]"
					:style="[left ? {left: left + 'px'} : {}, bottom ? {bottom: bottom + 'px'} : {}, top ? {top: top + 'px'} : {}, right ? {right: right + 'px'} : {}, carre ? {minWidth: '32px', maxWidth: '32px'} : {minWidth: '32px'}, styles]"
					:small="small"
					:x-small="carre"
					:height="large ? 42 : medium ? 36 : xSmall ? 28 : 30"
					@click.stop="click"
					:depressed="flat"
					:disabled="disabled"
					:loading="loading"
					:dark="dark"
					v-on="toolTip ? on : ''"
					:href="href"
					:target="href ? '_blank': null"
			>
				<v-icon v-if="iconVal && iconPosition === 'center'" :size="carre ? iconSize ? iconSize : '14': iconSize ? iconSize : iconSmall ? '18':'20'" :color="iconColor">{{ iconVal }}</v-icon>
				<v-row v-else :class="noWrap ? 'flex-nowrap':''" dense align="center">
					<v-col v-if="label || iconPosition !== 'center'" cols="auto">
						<v-icon v-if="iconVal && iconPosition === 'left'" :size="carre ? '14': iconSmall ? '18':'20'" :color="iconColor">{{ iconVal }}</v-icon>
					</v-col>
					<v-col v-if="label">
						<div class="font-weight-bold text-truncate" :class="small ? 'caption-1':'paragraph'">{{ label }}</div>
					</v-col>
					<v-col v-if="label || iconPosition !== 'center'" cols="auto">
						<v-icon v-if="iconVal  && iconPosition === 'right'" :size="carre ? '14': iconSmall ? '18':'20'" :color="iconColor">{{ iconVal }}</v-icon>
					</v-col>
				</v-row>
			</v-btn>
		</template>
		<span class="caption-1">{{ toolTip }}</span>
	</v-tooltip>
</template>

<script>
	export default {
		name: "Button",
		props: {
			block: {type: Boolean},
			bottom: {type: Number},
			carre: {type: Boolean},
			classes: {type: String},
			click: {type: Function, default: () => {}},
			color: {type: String},
			dark: {type: Boolean, default: false},
			disabled: {type: Boolean},
			fab: {type: Boolean},
			fixed: {type: Boolean},
			flat: {type: Boolean},
			icon: {type: Boolean},
			iconColor: {type: String},
			iconPosition: {type: String, default: 'center'},
			iconSize: {type: String},
			iconSmall: {type: Boolean},
			iconVal: {type: String},
			label: {type: String},
			large: {type: Boolean},
			left: {type: Number},
			loading: {type: Boolean},
			medium: {type: Boolean},
			noWrap: {type: Boolean},
			right: {type: Number},
			small: {type: Boolean},
			styles: {},
			tile: {type: Boolean},
			toolTip: {type: String},
			top: {type: Number},
			xSmall: {type: Boolean},
			show: {type: Boolean, default: true},
			href: {},
			target:{}
		}
	}
</script>

<style scoped>

</style>
